.App {
    text-align: center;
    font-size: 1rem;
}

.flexbox {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 6rem;
    height: 100vh;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.overlay-loader {
    position: absolute;
    left: 50%;
    height: 100%;
}

.Warning {
    color: crimson;
}

.aggregate-metrics-block {
    display: flex;
    flex-direction: row;
    position: absolute;
    background-color: lightgrey;
    border-left-width: 0;
    border-top-width: 1px;
    border-right-width: 1px;
    border-bottom-width: 1px;
    border-color: black;
    border-style: solid;
    margin: 0;
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;
    z-index: 2;
    justify-content: center;
}

.graphs-holder img {
    width: 46.5rem;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /*font-size: calc(10px + 2vmin);*/
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}


.node {
    /*padding: 10px;*/
    /*padding-top: calc(10px + 1rem);*/
    border: 1px solid black;
    cursor: text;
    /*user-select: text !important;*/
    pointer-events: all;
    width: 20vw;
    height: 20vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.react-flow__node {
    user-select: text !important; /* Use text instead of none to allow text selection */
}

.opening-filters {
    z-index: 2;
    padding: 10px;
    /*background-color: grey;*/
    /*border-right: 1px solid black;*/
    /*border-bottom: 1px solid black;*/
    border-bottom-right-radius: 50px;
}

.abs {
    position: absolute;
    z-index: 2;
}

.std-button-class {
    border: 1px black solid;
    border-radius: 12px;
    cursor: pointer;
    background-color: rgba(255, 255, 255, 1);;
    font-weight: bold;
    text-underline: black;
    grid-auto-flow: column;
    grid-column-gap: 10px;
    margin-left: 2px;
    margin-right: 2px;
}

.std-button-class:hover {
    background-color: rgba(255, 255, 255, 0.5);
    box-shadow: 3px 3px rgb(0, 0, 0);
}

.width12 {
    width: 12rem;
    display: grid;
}

.hide, .hide-settings {
    display: none;
    z-index: 0;
}

.settings {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.metrics {
    padding: 1px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.refresh {
    display: flex;
    justify-content: center;
    align-items: center;
}


.ico {
    cursor: pointer;
    border-radius: 50%;
    border-width: 0;
    background-color: transparent;
    /*position: relative;*/
}

.ico:hover {
    animation: spin 4s linear infinite;
    color: rgba(0, 0, 0, 50%);
}

.ico:hover + .hide-settings {
    display: block;
    color: black;
}

.wheel-container {
    border-radius: 50%;
    border-width: 0;
    z-index: 3;
    cursor: pointer;
    background-color: transparent;
    position: relative;
    will-change: transform;
    transition: transform 1000ms;
    transition-timing-function: ease-out;

}

.wheel-container:hover {
    transform: rotate(180deg);
    transition-timing-function: ease-out;
}

.refresh-spinner {
    animation: spin 1s linear infinite;
    border: 0;
    animation-timing-function: ease-out;
}

.metrics-container {
    border-radius: 50%;
    border-width: 0;
    z-index: 2;
    cursor: pointer;
    background-color: transparent;
    position: relative;
    will-change: transform;
    transition: transform 1000ms;
}

.metrics-container:hover {
    transform: scale(1.2);
}

.close-container {
    padding: 0;
    border-radius: 50%;
    cursor: pointer;
}

.close-container:hover {
    /*background-color: black;*/
    /*box-shadow: 3px 3px white;*/
    color: rgba(0, 0, 0, 50%);
}

.profile-icon {
    padding: 5px;
}


.graph-place-holder {
    height: 600px;
    display: table;
    width: 1000px;
    background-color: white;
}

.graph-place-holder .text {
    display: table-cell;
    vertical-align: middle;
    font-size: 24px;
}


@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    100% {
        transform: rotate(360deg);
    }
}

.metrics-container:hover + .hide-settings {
    display: block;
    color: black;
}

.wheel-container:hover + .hide-settings {
    display: block;
    color: black;
    z-index: 2;
}

.settings-icon {
    color: rgba(0, 0, 0, 50%)
}


/* HTML: <div class="loader"></div> */

.loader {
    width: 250px;
    aspect-ratio: 1;
    display: grid;
}

.loader::before,
.loader::after {
    content: "";
    grid-area: 1/1;
    --c: no-repeat radial-gradient(farthest-side, #25b09b 92%, #0000);
    background: var(--c) 50% 0,
    var(--c) 50% 100%,
    var(--c) 100% 50%,
    var(--c) 0 50%;
    background-size: 20px 20px;
    animation: l12 2s infinite;
}

.loader::before {
    margin: 4px;
    filter: hue-rotate(45deg);
    background-size: 10px 10px;
    animation-timing-function: linear
}

@keyframes l12 {
    100% {
        transform: rotate(-.5turn)
    }
}

.pointer {
    cursor: pointer;
}

.pointer label, input {
    cursor: pointer;
}

.node-selected {
    box-shadow: black 0 0 50px;
}

.ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}


.super-header {
    border-bottom: 1px solid black;
    /*border-top: 0px;*/
    padding: 5px;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    top: -1rem;
    background-color: white;
    color: black;
    display: flex;
    flex-direction: row;
    width: calc(100% - 10px);
    justify-content: space-between;
}

.left0 {
    left: 0;
    border-top-right-radius: 1rem;
}

.bottom {
    bottom: 0;
}

.effect1 {
    transition: transform 0.3s, background-color 0.3s, color 0.3s;
}

.effect1:hover {
    transform: scale(1.1);
    background-color: lightskyblue;
    color: white;
}

.effect1:active {
    background-color: lightsteelblue;
    box-shadow: 0 5px #666;
    transform: translateY(4px);
}

.node-metric-graph {
    z-index: 100;
    background-color: lightsteelblue;
    border-radius: 20px;
    flex-direction: column;
    border: thin solid black;
    display: flex;
}

.node-metric-graph img {
    padding: 0.25rem;
    border-radius: 20px;
}

.metric-graph-title {
    font-weight: 400;
    font-size: 20px;
    text-underline: black;
    padding: 0.5rem;
}

.right0 {
    right: 0;
    border-top-left-radius: 1rem;
}

.right10 {
    right: 10px;
}

.bottom10 {
    bottom: 10px
}

.node-footer {
    bottom: 0rem;
    border: 1px solid black;
    padding: 0.4rem;
    /*border-radius: 1rem;*/
    background-color: white;
    color: black;
}

.top-right {
    position: absolute;
    right: 3rem;
}

.top-left {
    position: absolute;
    left: 3rem;
}

.metric-footer {
    bottom: -1rem;
    left: 3.8rem;
    border: 1px solid black;
    padding: 0.1rem;
    border-radius: 3rem;
    background-color: lightblue;
    color: black;
    justify-content: center;
    flex-direction: column;
}

.aggregate-metric-footer {
    bottom: -2.5rem;
    border: 1px solid black;
    border-radius: 3rem;
    background-color: whitesmoke;
    color: black;
    justify-content: center;
    flex-direction: column;
}

select {

    /* styling */
    background-color: white;
    border: thin solid blue;
    border-radius: 4px;
    display: inline-block;
    font: inherit;
    padding: 0.25rem;

    /* reset */

    margin-left: 4px;
    box-sizing: border-box;
}

.bubble {
    cursor: pointer;
    border: 2px solid grey;
    border-radius: 8px;
    width: 12.75rem;
    transition: transform 0.3s, background-color 0.3s, color 0.3s;
}

.bubble:hover {
    transform: scale(1.1);
    background-color: whitesmoke;
    color: white;
}

.bubble:active {
    background-color: cornflowerblue;
    box-shadow: 0 3px #666;
    transform: translateY(2px);
}

.edit-manager {
    justify-content: center;
}

.unsaved {
    color: red;
}

.profile-manager {
    width: 300px;
    background-color: lightgray;
    border: black solid 1px;
    border-bottom-left-radius: 20px;
    border-top: 0px;
    border-right: 0px;
}

.profile-selection-holder{
    cursor: pointer !important;
}

.cursor-input {
    cursor: text;
}

input {
    max-width: 6rem;
}

.mr-1 {
    margin-right: 1rem;
}

.workers-button {
    cursor: pointer;
    height: 2.5rem;
    width: 2.5rem;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.workers-button:hover {
    background-color: rgba(255, 255, 255, 0.7);
}

.hide-workers {
    color: white;
    background-color: rgba(255, 0, 255, 0.5);
}

.hide-workers:hover {
    background-color: rgba(255, 0, 255, 0.7);
}

/* Prevent text selection */
.no-select {
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
}

.get-info-button {
    background-color: rgba(255, 255, 255, 0.5)
}

.get-info-button:hover {
    background-color: rgba(255, 255, 255, 0.7)
}

.dummy{}

.semi-transparent{
    background-color: rgba(255, 255, 255, 0.5);
    padding: 5px;
}
.semi-transparent:hover{
    background-color: rgba(255, 255, 255, 1);
}

.react-flow__attribution{
    display: none;
}

.button-section{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
}

.full-view{
    display: flex;
    /*height: 100vh;*/
    /*width: 100vw;*/
    flex-direction: column;
}

.dummy-change{}

.copy-info:hover{
    background-color: rgba(255, 255, 255, 0.7);
}

.status{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.copy-status:hover{
    color: black
}